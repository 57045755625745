<template>
  <div>
    <div class="container">
      <el-form  ref="form" :model="form" label-width="100px"  style="margin-top: 20px" label-position="left">
        <el-row>          
          <el-form-item label="省份/车站" style="margin-left: 30px">
            <el-cascader              
              :show-all-levels="false"
              placeholder="请选择省份/车站"
              :options="areaStationList"
              :props="seleprops"
              v-model="form.stationId"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <!-- <el-form-item label="媒体类型"  style="margin-left:30px;">
              <el-select v-model="form.typeArray" multiple clearable  placeholder="请选择媒体类型">
                <el-option
                  v-for="item in mediaTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item> -->
          <el-switch v-if="showFree" style="margin-top:15px;margin-left:20px;" v-model="form.isFree" active-text="赠送" inactive-text="非赠送"></el-switch>
          <el-button
            style="margin-left: 30px; height: 20px"
            type="primary"
            @click="onMediaGroupQuery"
            >检索</el-button>
            
        </el-row>
        <el-row>
          <!-- <el-form-item label="选择投放位置" style="margin-left: 30px">
            <el-select
              v-model="form.ptArray"
              multiple
              placeholder="请选择投放位置"
            >
              <el-option
                v-for="item in positionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

           <!-- <el-form-item label="关键字"  style="margin-left:30px;">
            <el-input  v-model="form.key" placeholder="请输入媒体编号关键字"></el-input>
          </el-form-item> -->
          
        </el-row>
          
        <!-- </el-row> -->
      </el-form>
    </div>
    <div>
      <el-divider>可选资源</el-divider>
      <!-- :data="SearchMediaGroupList" -->
      <el-table
        ref="tableObj"          
        :data="SearchMediaGroupList.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        border :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        style="width: 100%; margin-top: 10px; font-size: x-small" stripe
      >
        <!-- <el-table-column type="selection" reserve-selection width="55"></el-table-column> -->
        <el-table-column prop="area" align="center"  width="80"  sortable label="大区"></el-table-column>
        <el-table-column prop="province" align="center"  width="60"  sortable label="省份"></el-table-column>
        <el-table-column prop="station" align="center" width="140" sortable label="车站">
          <template v-slot="scope">
              <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" width="100" sortable label="套餐名称"></el-table-column>                                         
        <el-table-column prop="typeName" align="center" width="80" sortable label="媒体形式"></el-table-column>
        <el-table-column prop="position" align="center" width="80" sortable label="位置"></el-table-column>
        <el-table-column prop="totalCount" align="center"  width="60" sortable label="数量"></el-table-column> 
        <el-table-column prop="medias" align="center"   sortable label="媒体资源">
          <template v-slot="scope">
                                        <!-- <div style="display:flex;justify-content:center">
                                            <p  v-html="scope.row.medias"></p>
                                        </div> -->
            <!-- <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                <div v-else>{{item.mediaCode}}</div>
            </div> -->
            <div v-if="scope.row.collapse==-1"><!-- 无需折叠 -->
                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                    <div v-else>{{item.mediaCode}}</div>
                </div>
            </div>
            <div v-else-if="scope.row.collapse==1"><!-- 折叠状态 -->
                <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                    <div v-if="index<4">
                                                        <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                                        <div v-else>{{item.mediaCode}}</div>                                                
                                                    </div>
                </div>
                <i class="el-icon-arrow-down" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
            </div>
                <div v-else><!-- 展开状态 -->
                    <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                                    <div v-else>{{item.mediaCode}}</div>                                            
                    </div>
                    <i class="el-icon-arrow-up" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
                </div>

            <!-- <el-button type="text" @click="openPackage(scope.row.groupId)">{{scope.row.name}}</el-button> -->
          </template>
        </el-table-column>
        <el-table-column prop="id" align="center" label="投放版本" >
          <template v-slot="scope">
						<el-button @click="onSelectItemClick(scope.row)" type="text">选择</el-button>
					</template>
        </el-table-column>

      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20,50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="SearchMediaGroupList.length">
      </el-pagination>

      <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
            <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
        </el-dialog>

        <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                <div style="height:2800px;">
                    <station @close="viewStationVisible=false" :station="station"/>
                </div>
        </el-dialog>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import Station from '@/views/Business/Station';

export default {
  name: "baseform",
  components:{MediaDetail,Station},
  props: {
			groupId: {
				type: Number,
				default: 1
			},
      exceptIds:{
        type:String,
        default:''
      },
      showFree:{
        Boolean,
        default:false,
      }
  },
  model: {
		prop: 'form',
		event: 'change'
  },
  async created() {
      this.clearSearchList();
  },

  computed: {
    ...mapGetters([
      "mediaTypeList",
      "positionList",   
      "GetGroupList", 
      "areaStationList",  
    ]),
  },
  data() {
    return {
      form: {
        key: "",
        ptArray: [],        
        typeArray:[],
        stationId:-1,
        selectObj:{},
        isFree:false,
      },
      seleprops: { multiple: true },
      SearchMediaGroupList: [],
      // 当前页
      currentPage: 1,
      // 每页多少条
      pageSize: 20,
      viewPackageVisible:false,
      viewMediaVisible:false,
      viewStationVisible:false,
    };
  },

  watch: {

  },

  async mounted() {
    await this.$store.dispatch('operate/mediaTypeList');
    await this.$store.dispatch("operate/positionList");
    await this.$store.dispatch("operate/areaStationList");
    // this.SearchMediaGroupList =this.canSelectList;
    // console.log('aaa')
  },

  methods: {
    clearSearchList(){
      this.SearchMediaGroupList=[];
    },

    onCollapse(item){
            console.log('collapse')
            if (item.collapse==1){
                item.collapse=0;
            }else{
                item.collapse=1;
            }
        },
    // clearSelectedList(){
    //   this.$refs.tableObj.clearSelection();
    // },

    openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

    async openPackage(val){
            await this.$store.dispatch('operate/GetPackageMediaList',{groupId:val}).then(()=>{   
                var obj=[];
                var path = getImagePath();
                // console.log('aa',this.GetPackageMediaList)
                this.GetPackageMediaList[1].forEach(element => {
                    var imgObj=[];
                    if (element.images){
                        var imgArr=element.images.split(';');                    
                        if (imgArr && imgArr.length>0){
                            imgArr.forEach(ele2 => {
                                imgObj.push(path+ele2);
                            });                
                        }
                    }                    
                    element.imgList=imgObj;
                    obj.push(element);
                });
                this.packageMediaList = obj;
                console.log('aa',this.packageMediaList)
                this.viewPackageVisible=true;      
            })   
        },

    async onMediaGroupQuery() {      
      const {stationId,key,typeArray,ptArray} = this.form;
      var sid=stationId;
      // debugger;
      if (!stationId?.length){
        this.$message({message: '请选择省份/车站',type: 'error'});
        return;
      }
      var stationIds='';
            stationId.forEach(ele => {
                if (stationIds==""){
                    stationIds=ele[2];
                }else{
                    stationIds=stationIds+','+ele[2];
                }
            });

      var typeIds='';
      if (typeArray){
        typeIds=typeArray.join(',');
      }
      var ptIds='';
      if (ptArray){
        ptIds=ptArray.join(',');
      }
      // console.log('do here')
      var belowGroupId = this.groupId;
      await this.$store.dispatch('operate/GetGroupList',{stationIds,typeIds,ptIds,key,belowGroupId,exceptIds:this.exceptIds});        
      this.SearchMediaGroupList=this.GetGroupList;
      // console.log('ssss',this.SearchMediaGroupList)
    },

    openMedia(items){
        this.imageList=items;
        this.viewMediaVisible=true;
    },

    onSelectItemClick(item) {				
      // this.form.selectObj = item;				
      // const obj = {...this.form};
      // console.log('obj',this.form.isFree);
      // console.log('onSelectItemClick')
      if (this.form.isFree && !item.canFree){
        this.$message({message: '枢纽站资源，不可赠送，详情请联系高铁资源中心',type: 'error'});
        return;
      }
      this.$emit('change', item,this.form.isFree);
    },

    // 每页多少条
        handleSizeChange(val) {
            this.pageSize = val;
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
        },

    open() {
      // console.log("do open");
      this.$nextTick(() => {
        //  执行echarts方法
      });
    },
  },
};
</script>

<style scoped>
</style>