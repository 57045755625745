<template>
  <div>
      <div>
                        <el-table
                            :data="packageData.slice((mediaGroup.currentPage-1)*mediaGroup.pageSize,mediaGroup.currentPage*mediaGroup.pageSize)"
                            border
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 20px;font-size: x-small;">		
                            <!-- <el-table-column>
                                <template v-slot="scope">
                                    <el-carousel :interval="4000" type="card" height="200px">
                                        <el-carousel-item v-for="item in scope.row.imgList" :key="item">
                                        <el-image :src="item" >
                                            <div class="image-slot">
                                                加载中<span class="dot">...</span>
                                            </div>
                                        </el-image>
                                        </el-carousel-item>
                                    </el-carousel>                                    
                                </template>
                            </el-table-column> -->
                            <el-table-column prop="station" align="center" sortable label="车站">
                                <template v-slot="scope">
                                    <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                                </template>
                            </el-table-column>
                            <el-table-column prop="mediaCode" align="center" sortable label="资源编号">
                                <template v-slot="scope">
                                    <el-button v-if="scope.row.imgList &&  scope.row.imgList.length>0" type="text" @click="openMedia(scope.row.imgList)">{{scope.row.mediaCode}}</el-button>
                                    <span v-else>{{scope.row.mediaCode}}</span>
                                </template>
                            </el-table-column>                                                            
                            <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>
                            <el-table-column prop="position" align="center" sortable label="位置">
                                <template v-slot="scope">
                                    <span>{{scope.row.position}}/{{scope.row.address}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="adSize" align="center" sortable label="尺寸(㎡)"></el-table-column> 
                            <el-table-column prop="adArea" align="center" sortable label="面积(㎡)"></el-table-column> 
                        </el-table>
                        <el-pagination
                                        @size-change="handleMediaGroupChange"
                                        @current-change="handleMediaGroupChange"
                                        :current-page="mediaGroup.currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="mediaGroup.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="packageData.length">
                        </el-pagination>

                        
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="close()">关闭</el-button>
                        </span>                    
                    </div>
        <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
            <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
        </el-dialog>
        <el-dialog title="站点介绍" v-model="viewStationVisible" width="80%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body> 
            <div style="height:2800px;">
            <station @close="viewStationVisible=false" :station="station"/>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import Station from '@/views/Business/Station';

export default {
    props:['packageData'],
    components:{MediaDetail,Station},
    data() {
        return {
            mediaGroup:{
                // 当前页
                currentPage: 1,
                // 每页多少条
                pageSize: 20,
            },
            viewMediaVisible:false,
            imageList:[],
            station:'',
            viewStationVisible:false,
        };
    },

    methods:{
        // 每页多少条
        handleMediaGroupChange(val) {
            this.mediaGroup.pageSize = val;
        },
        // 当前页
        handleMediaGroupChange(val) {
            this.mediaGroup.currentPage = val;
        },
        close(){
            // this.$parent.closeDetail();
            this.$emit('close');
        },
        openMedia(items){
            this.imageList=items;
            this.viewMediaVisible=true;
        },
        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },
        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },

    }
}
</script>

<style>

</style>