<template>
    <div>        
       <el-form ref="form" :model="curForm" label-width="150px" label-position="left" >
            
                <el-row style="display:flex;justify-content:flex-end;">
                    <!-- <el-tooltip class="item" effect="dark" placement="right" content="加入购物车"> -->
                    <div v-if="curItem.groupLevel===2">
                        <el-button style="float: right; padding: 0px 0;margin-top: -8px;" type="text" @click="onAdjust(curItem)">调整资源</el-button>
                    </div>
                    <!-- </el-tooltip> -->
                </el-row>
                <div  class="product-name">{{curItem.fullname}}</div>
            
                        <el-row style="margin-top:20px;">
                            <el-form-item label="投放周期" >    
                                <el-date-picker                                
                                    v-model="curForm.dateArray"
                                    style="width:262px;"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"                                   
                                    :clearable="false"
                                    :shortcuts="shortcuts"
                                    @change ="handleDateChange"
                                    >
                                </el-date-picker>
                            </el-form-item>  
                        </el-row>
                       
                        <!-- currentMediaType.haveLed==true" >       -->
                        <el-row v-if="tmpLengthList.length>0">                      
                            <el-form-item  v-if="curItem.groupLevel===1" label="版本时长" style="width:372px;">                            
                                <el-select @change="lengthChanged" v-model="curForm.length"  placeholder="请选择版本时长">
                                    <el-option
                                    v-for="item in tmpLengthList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item v-if="currentMediaType.haveLed" label="每日投放频次" style="width:372px;">                            
                                <el-select @change="timesChanged" v-model="curForm.times"  placeholder="请选择每日投放频次">
                                    <el-option
                                    v-for="item in timesList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                        
                        <el-row v-if="currentMediaType.haveLc==true" >      
                            <el-form-item label="投放组数" style="width:372px;">   
                                <el-input  @change="zushuChanged" v-model="curForm.zuShu" placeholder="请输入投放列车组数"></el-input>
                                <span style="color:red;">{{currentMediaType.minZuShu }}组以内(含{{ currentMediaType.minZuShu }})
                                    <!-- <span v-if="selectedList?.length>0">,若要设置不同组数，请返回上一页面分别加入购物车</span> -->
                                </span>
                            </el-form-item>
                        </el-row>
           
           <el-row v-if="curItem.groupLevel===1">
                <div v-if="selectedList && selectedList.length>0">
                </div>
                <div v-else>
                    <div v-if="curItem.canFree==1">
                        <el-switch v-model="curForm.isFree" @change="isFreeChanged" active-text="赠送" inactive-text="非赠送"></el-switch>
                    </div>
                    <div v-else>枢纽资源，不可赠送，详情联系高铁资源中心</div>
               </div>
           </el-row>    
                        
           <el-row style="margin-top:20px;">
                <!-- <div v-if="curItem.remark"><span  style=" margin-left:20px;float: right; color: red; font-size: 13px">{{curItem.remark}}</span></div> -->
                <div v-if='curForm.cost>0'>
                    <div>
                        <span v-if="change==true || curItem.groupLevel===1">刊例价格(万元)：</span>
                        <span v-else>产品包价格(万元)：</span>
                        <span  style=" margin-left:20px;float: right; color: red; font-size: 13px">{{curForm.cost}}万元
                            <span style="margin-left:20px;float: right; color: black;" v-if="curForm.onetimeCost && curForm.onetimeCost>0">其中一次性费用<span style="margin:0 5px; color: red;">{{ curForm.onetimeCost }}</span>万元</span>
                        </span>
                        
                    </div>
                    <div style="margin-top:10px;" v-if="change==false && curItem.groupLevel===2">
                        <span >产品包折扣:
                            <span  style=" margin-left:20px;float: right; color: red; font-size: 13px">{{curItem.discount}}</span>
                        </span>
                    </div>
                </div>
           </el-row>
                        
       </el-form>

       <el-dialog title="设置资源" v-model="adjustVisible" v-if="adjustVisible" width="60%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    @close='closeSelectProductMediaDialog'                                    
                                    append-to-body>
            <adjust @close="adjustVisible=false" :cur-item="curItem"  :table-data="tmpData && tmpData.length>0?tmpData:tableData"  @changeFinished="changeFinished"/> 
       </el-dialog>
                    
        <div class="button-footer">                      
           <span >
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="onAddCart">加入购物车</el-button>
           </span>                    
        </div>          

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Adjust from './Adjust.vue';
import {getCurrentTime} from "@/utils/time";
import {getPeriodPrice} from "@/utils/business";

export default {
    name: 'baseform',
    components:{Adjust},
    props:['curItem','tableData','sDate','eDate','times','selectedList'],
    computed: {...mapGetters(['GetPlanPrice_ByCost','BuildCartItem','GetPlanPrice_ByGroupIds'])},
    data() {
        return {
           curForm: {    
              dateArray:[],//getLatestMonthDateArrayByDate({date: new Date(), delayNum: 2, formatValue: 'yyyy-MM-dd'}),
              length:'15',
              times:100,
              isFree:false,
              cost:0,
              zuShu:'1',
              onetimeCost:0,
           },
           currentMediaType:{
                haveLed:false,
                haveDx:false,
                haveLc:false,
                haveLcds:false,
                minZuShu:0,
            },

            adjustVisible:false,
            tmpData:[],
            change:false,
            // timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'400次',value:400},
            //     {label:'500次',value:500},{label:'600次',value:600},{label:'700次',value:700}],
            timesList:[],
            lengthList:[{label:'10秒',value:'10'},{label:'15秒',value:'15'},{label:'30秒',value:'30'},{label:'2分钟',value:'120'}],
            tmpLengthList:[],
        };
    },
    
    async mounted() {
        console.log('mounted')
        // console.log('this.curItem',this.curItem)
        // console.log('this.selectedList',this.selectedList)
        this.curForm.onetimeCost=0;
        this.setCurrentMediaType();
        if (this.sDate){
            this.curForm.dateArray=[this.sDate,this.eDate];
            this.curForm.times=this.times;
        }else{
            if (localStorage.getItem('sYm')){
                this.curForm.dateArray=[localStorage.getItem('sYm'),localStorage.getItem('eYm')];
            }
        }
        if (this.curItem.groupLevel==2){
            this.timesList=[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'500次',value:500},
                {label:'1000次',value:1000}];
        }else{
            this.timesList=[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'500次',value:500},
                {label:'1000次',value:1000}];
        }
        // console.log('aabb')
        // console.log('aaaa',this.sDate,this.eDate,this.curForm.dateArray)
        this.calculatePrice();
    },


    methods: {
        setCurrentMediaType(){
            if (this.selectedList?.length>0){
                this.selectedList.forEach(ele => {
                    if (JSON.parse(ele.haveLed.toLowerCase())){
                        this.currentMediaType.haveLed = true;
                    }
                    if (JSON.parse(ele.haveDx.toLowerCase())){
                        this.currentMediaType.haveDx = true;
                    }
                    if (JSON.parse(ele.haveLc.toLowerCase())){
                        this.currentMediaType.haveLc = true;
                        if (this.currentMediaType.minZuShu==0 || this.currentMediaType.minZuShu>ele.totalCount){
                            this.currentMediaType.minZuShu = ele.totalCount
                        }
                    }
                    if (ele.province=='列车电视'){
                        this.currentMediaType.haveLcdx = true;
                    }
                });
            }else{
                this.currentMediaType.haveLed = JSON.parse(this.curItem.haveLed.toLowerCase());//Json.parse 转布尔型
                this.currentMediaType.haveDx = JSON.parse(this.curItem.haveDx.toLowerCase());
                this.currentMediaType.haveLc = JSON.parse(this.curItem.haveLc.toLowerCase());
                this.currentMediaType.minZuShu = this.curItem.totalCount;

                if (this.curItem.province=='列车电视'){
                    this.currentMediaType.haveLcdx=true;
                }
            }
            this.tmpLengthList=[];
            // console.log('aaa',this.currentMediaType.haveLed, this.currentMediaType.haveLcdx)
            if (this.currentMediaType.haveLed && this.currentMediaType.haveLcdx){
                this.tmpLengthList=[{label:'15秒',value:'15'}];
                console.log('a1')
            }else{
                if (this.currentMediaType.haveLed && (!this.currentMediaType.haveLcdx || this.currentMediaType.haveLcdx=='undefined')){
                    this.tmpLengthList=[{label:'10秒',value:'10'},{label:'15秒',value:'15'}];
                    console.log('a2')
                }else if ((!this.currentMediaType.haveLed || this.currentMediaType.haveLed=='undefined') && this.currentMediaType.haveLcdx){
                    this.tmpLengthList=[{label:'15秒',value:'15'},{label:'30秒',value:'30'},{label:'2分钟',value:'120'}];
                    console.log('a3')
                }
            }
        },

        handleDateChange(){            
            this.calculatePrice();            
        },
        timesChanged(){            
            this.calculatePrice();            
        },
        lengthChanged(){            
            this.calculatePrice();            
        },
        isFreeChanged(){            
            this.calculatePrice();            
        },
        zushuChanged(){
            this.calculatePrice();
        },

        calculatePrice(){
            if (this.curForm.isFree===true){
                this.curForm.cost=0;
                return;
            }
            // console.log('aabb',this.curItem)
            const {dateArray: [sYM, eYM], times,length,zuShu} = this.curForm;            
            if (sYM){
                var sDate = new Date(sYM).toLocaleDateString();
                var eDate = new Date(eYM).toLocaleDateString();
  
                var groupIds='';
                
                if (this.selectedList?.length>0){
                    this.selectedList.forEach(ele => {
                        groupIds=groupIds+','+ele.groupId;                       
                        if (ele.typeIds*1==4){
                            this.curItem.haveLc=true;                                
                        }
                    });
                         
                    this.$store.dispatch('operate/GetPlanPrice_ByGroupIds', {groupIds,sDate,eDate,times,length,zuShu,disCount:10}).then(()=>{
                        this.curForm.cost=this.GetPlanPrice_ByGroupIds[0].Price;
                        this.curForm.onetimeCost=this.GetPlanPrice_ByGroupIds[0].oneTimeCost;
                        this.curItem.kanli=this.GetPlanPrice_ByGroupIds[0].monthCost;
                        // console.log('aaaa',this.GetPlanPrice_ByGroupIds)
                    })
                }else{
                    // if(this.curItem.typeIds*1==4){
                    //     this.curItem.haveLc=true;
                    // }                
                    
                    this.$store.dispatch('operate/GetPlanPrice_ByGroupIds', {groupIds:this.curItem.groupId,sDate,eDate,times,length,zuShu,disCount:10}).then(()=>{
                        this.curForm.cost=this.GetPlanPrice_ByGroupIds[0].Price;
                        this.curForm.onetimeCost=this.GetPlanPrice_ByGroupIds[0].oneTimeCost;
                        this.curItem.kanli=this.GetPlanPrice_ByGroupIds[0].monthCost;
                        // console.log('bbbb',this.GetPlanPrice_ByGroupIds,this.GetPlanPrice_ByGroupIds[0].Price)
                    })
                }
                
            }
            
        },

        async onAddCart(){
            const {dateArray: [sYM, eYM], times,length,cost,zuShu,onetimeCost} = this.curForm;            
            if (!sYM){
                this.$message({message: `请设置投放周期！`,type: 'error'});
                return;
            }
            if (this.currentMediaType.haveLc && !zuShu){
                this.$message({message: `请填写投放组数！`,type: 'error'});
                return;
            }
            if (this.currentMediaType.haveLc && zuShu*1>this.currentMediaType.minZuShu*1){
                this.$message({message: `组数超限！`,type: 'error'});
                return;
            }
            localStorage.setItem('sYm', sYM);
            localStorage.setItem('eYm', eYM);
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            if (this.selectedList?.length>0){
                this.tmpData=[];
                // this.selectedList.forEach(async ele => {
                //     var isAdded = this.$store.state.cart.cartList.find(item => item.groupId === ele.groupId && item.item.sDate === sDate && item.item.eDate === eDate);
                //     if(isAdded === 'undefined' || !isAdded){   
                //         var obj ={};
                //         obj.addTime=getCurrentTime();
                //         obj.adjLog="";
                //         obj.change= false;                        
                //         obj.sDate= sDate;
                //         obj.eDate=eDate;
                //         obj.freeIds= "";
                //         obj.groupId=ele.groupId;
                //         obj.groupLevel= 1;
                //         if (ele.typeIds*1==1){obj.haveLed= true;}else{obj.haveLed=false;}
                //         if (ele.typeIds*1==2){obj.haveDx= true;}else{obj.haveDx=false;}
                //         if (ele.typeIds*1==3){obj.haveDigitalDx= true;}else{obj.haveDigitalDx=false;}
                //         if (ele.typeIds*1==4){obj.haveLc= true;}else{obj.haveLc=false;}
                        
                //         obj.haveSelectListIds="";                        
                //         if (ele.typeIds*1==4){
                //             obj.times=zuShu;
                //         }else{
                //             obj.times=times;
                //         }

                //         obj.name=ele.station+' '+ele.name;                        
                //         obj.pList= [];                        
                //         obj.selected= true;
                //         obj.length=length;
                //         obj.typeId=ele.typeIds*1;

                //         await this.$store.dispatch('operate/GetPlanPrice_ByGroupIds', {groupIds:obj.groupId,sDate,eDate,times,length,zuShu,disCount:10}).then(()=>{
                //             obj.cost = this.GetPlanPrice_ByGroupIds[0].Price;
                //             obj.kanli = obj.cost - this.GetPlanPrice_ByGroupIds[0].oneTimeCost;
                //             obj.totalPrice=obj.cost;
                //             this.$store.dispatch('cart/addCart', {selItem:obj});
                //         })

                //         // obj.cost=getPeriodPrice(sDate,eDate,itemcost)*1+ele.onetimeCost*1;
                //         // obj.kanli=itemcost;
                //         // obj.totalPrice=obj.cost;//itemcost;
                        
                        
                //         // this.$store.dispatch('cart/addCart', {selItem:obj});
                //     }              
                // });
                // this.$message({message: '成功加入购物车！',type: 'success'});
                // this.$emit('close');
                Promise.all(this.selectedList.map((ele)=>{
                    return new Promise(async (resolve, reject)=>{
                        var isAdded = this.$store.state.cart.cartList.find(item => item.groupId === ele.groupId && item.item.sDate === sDate && item.item.eDate === eDate);
                        if(isAdded === 'undefined' || !isAdded){  
                            var obj ={};
                            obj.addTime=getCurrentTime();
                            obj.adjLog="";
                            obj.change= false;                        
                            obj.sDate= sDate;
                            obj.eDate=eDate;
                            obj.freeIds= "";
                            obj.groupId=ele.groupId;
                            obj.groupLevel= 1;
                            if (ele.typeIds*1==1){obj.haveLed= true;}else{obj.haveLed=false;}
                            if (ele.typeIds*1==2){obj.haveDx= true;}else{obj.haveDx=false;}
                            if (ele.typeIds*1==3){obj.haveDigitalDx= true;}else{obj.haveDigitalDx=false;}
                            if (ele.typeIds*1==4){obj.haveLc= true;}else{obj.haveLc=false;}
                            
                            obj.haveSelectListIds="";                        
                            if (ele.typeIds*1==4){
                                obj.times=zuShu;
                            }else{
                                obj.times=times;
                            }

                            obj.name=ele.station+' '+ele.name;                        
                            obj.pList= [];                        
                            obj.selected= true;
                            obj.length=length;
                            obj.typeId=ele.typeIds*1;
                            console.log('obj.groupId',obj.groupId)
                            await this.$store.dispatch('operate/GetPlanPrice_ByGroupIds', {groupIds:obj.groupId,sDate,eDate,times,length,zuShu,disCount:10}).then(()=>{
                                obj.cost = this.GetPlanPrice_ByGroupIds[0].Price;
                                obj.kanli = this.GetPlanPrice_ByGroupIds[0].monthCost;//obj.cost - this.GetPlanPrice_ByGroupIds[0].oneTimeCost;
                                obj.totalPrice=obj.cost;
                                this.$store.dispatch('cart/addCart', {selItem:obj});
                            }) 
                        }
                        
                        resolve();
                    })

                })).then((data)=>{
                    console.log('aaa then');
                    // this.$message({message: '成功加入购物车！',type: 'success'});
                    this.$emit('close');
                })

            }else{
                const isAdded = this.$store.state.cart.cartList.find(item => item.groupId == this.curItem.groupId && item.item.sDate == sDate && item.item.eDate == eDate);
                if(isAdded){
                    this.$message({message: '已加购该时段的资源，请勿重复加购',type: 'error'});
                    return;
                }
                if(this.curItem.typeIds*1==4){
                    this.curItem.haveLc=true;
                    // this.curItem.length=zuShu;
                    this.curItem.times=zuShu;
                    this.curItem.length=length;
                    if (this.curForm.isFree){
                        this.curItem.cost=0;//cost*1;
                    }else{
                        this.curItem.cost=cost;//cost*1+(onetimeCost?onetimeCost:0)*1;//zuShu*cost;//this.curItem.cost;
                    }
                }else{
                    this.curItem.haveLc=false;
                    this.curItem.length=length;
                    this.curItem.times=times;
                    if (this.curForm.isFree){
                        this.curItem.cost=0;//cost*1;        
                    }else{
                        this.curItem.cost=cost;//cost*1+(onetimeCost?onetimeCost:0)*1;//cost;        
                    }
                                
                }
                var tmpitem = JSON.parse(JSON.stringify(this.curItem));
                if (this.curItem.fullname){
                    tmpitem.name=this.curItem.fullname;
                }
                
                var itemStr = JSON.stringify(tmpitem);
                var tmpStr = JSON.stringify(this.tmpData);
                
                if (this.curItem.groupLevel==2 && this.curItem.count!==""){
                    if (this.curItem.count<this.tmpData.length || this.tmpData.length==0){
                        this.$message({message: '当前产品包最多可选择'+this.curItem.count+'个套餐,请进行调整',type: 'error'});
                        return;                        
                    }
                }

                await this.$store.dispatch('operate/BuildCartItem', {cost:this.curItem.cost,sDate,eDate,times:this.curItem.times,length:this.curItem.length,change:this.change,curItem:itemStr,tmpData:tmpStr}).then(()=>{
                    var obj = this.BuildCartItem;
                    console.log('be added item',obj)
                    this.$store.dispatch('cart/addCart', {selItem:obj});
                    this.$message({message: '成功加入购物车！',type: 'success'});
                    this.$emit('close');
                });
            }
        },

        //检查产品包有无调整
        checkChange(){
            var haveFree=false;
            this.tmpData.forEach(element => {
                if (element.isFree===true){
                    haveFree =true;
                }
            });
            if (haveFree===true){
                this.change =true;
                return;
            }
            if (this.tmpData.length!=this.tableData.length){
                this.change =true;
            }else{           
                var newList = [];            
                this.tmpData.forEach(element => {                    
                    if (element.newGroupId && element.newGroupId!==''){
                        newList.push(element.newGroupId+'');
                    }else{
                        newList.push(element.groupId+'');
                    }
                });
                var oldList = [];
                this.tableData.forEach(element => {
                    oldList.push(element.groupId+'');
                });

                newList.sort(function (p1, p2) {
                    return p1 - p2;
                })
                oldList.sort(function (p1, p2) {
                    return p1 - p2;
                })
                var nstr = (JSON.stringify(newList));
                var ostr = (JSON.stringify(oldList));
                if (nstr == ostr){
                    this.change = false;
                }else{
                    this.change = true;
                }
            }
        },

        onAdjust(){
            

            this.curItem.haveSelectList = [];
            // console.log('cur',this.curItem)
            this.adjustVisible=true;
        },

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },

        changeFinished(newList){
            console.log('newList',newList)
            var newCost=0;
            var freeIds='';
            newList.forEach(element => {
                newCost=newCost+parseInt(element.cost);
                if (element.isFree && element.isFree===true){
                    if (element.newGroupId && element.newGroupId!==''){
                        freeIds=freeIds+','+element.newGroupId;
                    }
                    else{
                        freeIds=freeIds+','+element.groupId;
                    }
                    
                }
            });
            this.curItem.newCost=newCost;
            this.curItem.haveSelectListIds = newList.haveSelectListIds;
            this.curItem.adjLog=newList.adjLog;
            this.curItem.freeIds=freeIds;
            this.tmpData=newList;
            this.checkChange();
            this.calculatePrice();
            this.adjustVisible=false;
        },

        close(){
            this.$emit('close');
        },
    }
};
</script>

<style scoped>
.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: xx-large;
    margin-top:-40px;
    color: #409EFF;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 20px;
    margin-left: 10px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>