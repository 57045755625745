<template>
    <div>  
        <!-- <div style="display:flex;justify-content:flex-end;margin-top:-20px;">
            <el-button type="text"  @click="onChangeMedia()">添加资源</el-button>
        </div> -->
        <div v-if="curItem.count">资源数量不超过   <span style="color:red">{{curItem.count}}</span>个 当前资源数量<span style="color:red">
                            <!-- {{haveSelectCnt}} -->
                            {{tmpData.length}}
                        </span>个</div> 
        <div style="display:flex;justify-content:space-between;width: 100%;margin-top:5px;">
            <el-input  v-model="detailKey" style="width:295px;" placeholder="请输入检索关键字"></el-input>

        </div>
                    <div >    
                            
                        <!-- :data="groupDetailMediaList"                -->
                        <!-- :data="tmpData.slice((zyxl.currentPage-1)*zyxl.pageSize,zyxl.currentPage*zyxl.pageSize)" -->
                            <el-table
                                    ref="productMediaTable"                                     
                                    :data="tmpData.filter(data => !detailKey || data.station.toLowerCase().includes(detailKey.toLowerCase())
                                             || data.name.toLowerCase().includes(detailKey.toLowerCase())
                                             || data.ydarea.toLowerCase().includes(detailKey.toLowerCase())
                                             || data.grade.toLowerCase().includes(detailKey.toLowerCase())
                                             || data.position.toLowerCase().includes(detailKey.toLowerCase()))"
                                    border
                                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                                    :row-key="getProductRowKey"
                                    @row-click="handleCurrentChange"
                                    @selection-change="selsChange"
                                    style="width: 100%;font-size: x-small;">
                                <!-- <el-table-column v-if="curItem.count" type="selection" reserve-selection="true" width="55"></el-table-column> -->
                                <el-table-column type="selection" reserve-selection="true" width="55"></el-table-column>
                                
                                <!-- <el-table-column prop="groupId" align="center" sortable label="id"></el-table-column>  	     -->
                                <el-table-column prop="ydarea" align="center" sortable label="大区"></el-table-column>  
                                <el-table-column prop="station" align="center" sortable label="车站">
                                    <template v-slot="scope">
                                        <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                                    </template>
                                </el-table-column>       
                                <el-table-column prop="grade" align="center" sortable label="车站等级"></el-table-column>    
                                <el-table-column prop="mediaGrade" align="center" width="100px" sortable label="媒体等级"></el-table-column>                      
                                <el-table-column prop="name" align="center" sortable label="套餐名称">
                                    <template v-slot="scope">
                                        <el-button type="text" @click="openPackage(scope.row.groupId)">
                                            <span v-if="scope.row.isFree==true" style="color:red;" >{{scope.row.name}}</span>
                                            <span v-else-if="scope.row.isNew==1" style="color:green;" >{{scope.row.name}}</span>
                                                <span v-else >{{scope.row.name}}</span>
                                        </el-button>
                                        
                                    </template>
                                </el-table-column>                                         
                                <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>
                                <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
                                <el-table-column prop="totalCount" align="center" sortable label="数量" width="55"></el-table-column>  
                                <el-table-column prop="mediaCode" align="center"  sortable label="状态">
                                            <template v-slot="scope">
                                                <span  :style="scope.row.statusName=='无效'?'color:red':''" >{{scope.row.statusName}}</span>
                                                
                                            </template>
                                </el-table-column>
                                
                                 <!-- v-if="!curItem.count && curItem.count!=0"                          -->
                                <!-- <el-table-column prop="id" width="205" align="center" label="操作" >
                                    <template v-slot="scope">                                        
                                        <el-button  type="text" @click="onChangeMedia(scope.row)">{{scope.row.newGroupLabel}}</el-button>                                        
                                        <el-button v-if="scope.row.newGroupId" type="text" @click="onRemoveChangeMedia(scope.row)">解除</el-button>
                                    </template>
                                </el-table-column> -->
                            </el-table> 
                            <div style="display:flex;justify-content:space-between;">
                                <div v-if="this.haveSelectList && this.haveSelectList.length>0">
                                    <el-button type="primary" @click="onRemove">移除</el-button>
                                </div>
                                <div>
                                    总共{{tmpData.length}}条
                                    <!-- <el-pagination
                                                @size-change="handleZyxlSizeChange"
                                                @current-change="handleZyxlCurrentChange"
                                                :current-page="zyxl.currentPage"
                                                :page-sizes="[20,50, 100]"
                                                :page-size="zyxl.pageSize"
                                                layout="total, sizes, prev, pager, next, jumper"
                                                :total="tmpData.length">
                                    </el-pagination> -->
                                </div>
                            </div>
                    </div>                         

                    <div class="button-footer">                      
                        <span >
                            <el-button @click="close()">取消</el-button>
                            <el-button type="primary" @click="onConfirmChange">确定</el-button>
                        </span>                    
                    </div>     
        <el-dialog title="选择资源" v-model="chooseMediaDialogVisible" width="60%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    @close='closeAddDialog'
                    append-to-body>
                    <select-media-package ref="selectMediaPackageObj" :show-free="'true'" :group-id="curChangeGroupId" :except-ids="currentUseIds" @change="changeItem"/>
                    <!-- <div style="display:flex;flex-direction:row-reverse;width: 100%;">
                        <el-button type="primary" @click="onAddProductMediaGroup">加入</el-button>
                    </div> -->
        </el-dialog>    
        <el-dialog title="套餐明细" v-model="viewPackageVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                <package-detail @close="viewPackageVisible=false" :package-data="packageMediaList"/>
        </el-dialog>  

        <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                <div style="height:2800px;">
                    <station @close="viewStationVisible=false" :station="station"/>
                </div>
        </el-dialog>   
    </div>
          
</template>
        
<script>
import { mapGetters } from "vuex";
import SelectMediaPackage from '@/views/Business/SelectMediaPackage.vue';
import {getToken,getImagePath} from "@/utils/auth";
import PackageDetail from '@/views/Business/Smart/PackageDetail';
import Station from '@/views/Business/Station';

    export default {
        components:{SelectMediaPackage,PackageDetail,Station},
        props:['curItem','tableData'],
        computed: {...mapGetters(['GetPackageMediaList'])},
        data() {
            return {
                tmpData:[],
                haveSelectList:[],
                zyxl:{
                    // 当前页
                    currentPage: 1,
                    // 每页多少条
                    pageSize: 20,
                },
                chooseMediaDialogVisible:false,
                curChangeGroupId:0,
                currentUseIds:'',
                currentadjLog:'',
                packageMediaList:[],
                viewPackageVisible:false,
                viewStationVisible:false,
                detailKey:'',
            }
        },

        async mounted() {
            
            this.tmpData=JSON.parse(JSON.stringify(this.tableData));
            console.log('tmpData',this.tmpData)
            this.$nextTick(() => {                
                //  执行echarts方法
                this.clearSelect();
            })
            
            // console.log('ddd',this.tableData)
        },

        created(){
            
        },
        

        methods: {
            onChangeMedia(item){
                if (item){
                    this.curChangeGroupId = item.groupId;
                }else{
                    this.curChangeGroupId = 0;
                }
            // var rate=(this.adjustCost+item.cost)/this.prodCostByMedia;
            // console.log('rate',rate)            
            this.getCurrentUseIds();
            // console.log('currentUseIds',this.currentUseIds)
            this.chooseMediaDialogVisible=true;
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        //获得已使用的套餐包
        getCurrentUseIds(){
            this.currentUseIds='';
            this.currentadjLog='';
            this.tmpData.forEach(element => {
                var gpId;
                if (element.newGroupId && element.newGroupId!==""){
                    gpId=element.newGroupId;
                    if (this.currentadjLog==''){
                        this.currentadjLog=element.groupId+':'+element.newGroupId
                    } else{
                        this.currentadjLog=this.currentadjLog+'|'+element.groupId+':'+element.newGroupId
                    }
                }else{
                    if (element.isNew && element.isNew==true){
                        if (this.currentadjLog==''){
                            this.currentadjLog='0:'+element.groupId
                        } else{
                            this.currentadjLog=this.currentadjLog+'|0:'+element.groupId
                        }
                    }
                    gpId=element.groupId;
                }
                if (this.currentUseIds==''){
                    this.currentUseIds = gpId;
                }else{
                    this.currentUseIds = this.currentUseIds+','+gpId;
                }

                // if (this.currentUseIds===""){
                //     this.currentUseIds=element.groupId;
                // }else{
                //     this.currentUseIds=this.currentUseIds+','+element.groupId;
                // }
                // if (element.newGroupId && element.newGroupId!==""){
                //     this.currentUseIds=this.currentUseIds+','+element.newGroupId;
                // }
            });
            console.log('this.currentadjLog',this.currentadjLog)
            // if (this.curItem.haveSelectList.length>0){
            //     this.curItem.haveSelectList.forEach(ele => {
            //         var gpId;
            //         if (ele.newGroupId){
            //             gpId=ele.newGroupId;
            //             if (this.currentadjLog==''){
            //                 this.currentadjLog=ele.groupId+':'+ele.newGroupId
            //             } else{
            //                 this.currentadjLog=this.currentadjLog+'|'+ele.groupId+':'+ele.newGroupId
            //             }                       
            //         }else{
            //             gpId=ele.groupId;
            //         }
            //         if (this.currentUseIds==''){
            //             this.currentUseIds = gpId;
            //         }else{
            //             this.currentUseIds = this.currentUseIds+','+gpId;
            //         }
            //     });
            // }
        },

        // 调整
        changeItem(item,isFree){
            this.$refs.selectMediaPackageObj.clearSearchList();
            // console.log('aa',item,isFree)
            if (this.curChangeGroupId === 0){
                var obj={};
                obj.canChange=0;
                var cost;                
                if (parseInt(item.typeIds)===1){
                    cost=item.cost10;
                }else{
                    cost=item.cost;
                }                
                obj.kanli=cost;

                if (isFree===true){
                    obj.cost=0;
                }else{
                    obj.cost=cost;
                }
                
                
                obj.groupId=item.groupId;
                obj.isNew=1;
                obj.name=item.name;
                obj.newGroupId='';
                obj.newGroupLabel='调整';
                obj.playDate=null;
                obj.position=item.position;
                obj.positionId=null;
                obj.rate=null;//:0.233333333
                obj.saleDate=null;
                obj.station=item.station;
                obj.totalCount=item.totalCount;                
                obj.typeId=parseInt(item.typeIds);                
                obj.typeName=item.typeName;
                obj.isFree=isFree;
                this.tmpData.push(obj);
            }else{
                this.tmpData.forEach(ele => {
                    if (ele.groupId==this.curChangeGroupId){                    
                        if (!ele.newGroupId){
                            // this.adjustCost = Number(this.adjustCost)+Number(item.cost);
                            // console.log('this.adjustCost',this.adjustCost)
                        }
                        ele.newGroupId = item.groupId;
                        ele.newGroupLabel = item.station+':'+item.name;
                        // ele.cost=item.cost10;
                        // console.log('isFree',isFree)
                        var cost;
                        
                        if (parseInt(item.typeIds)===1){
                            cost=item.cost10;
                        }else{
                            cost=item.cost;
                        }
                      
                        ele.kanli=cost;
                        ele.typeId=parseInt(item.typeIds);
                      
                        if (isFree===true){
                            ele.cost=0;
                        }else{
                            ele.cost=cost;
                        }
                        ele.isFree=isFree;
                    }else{
                        // if (parseInt(item.typeIds)===1){
                        //     ele.kanli=item.cost10;
                        // }else{
                            ele.kanli=item.cost;
                        // }                     
                        
                    }
                });
            }
            // console.log('dddd',this.tmpData)
            // this.curItem.haveSelectList = this.groupDetailMediaList;
            // this.haveSelectCnt = this.curItem.haveSelectList.length;
            this.chooseMediaDialogVisible=false;

        },

        //解除调整
        onRemoveChangeMedia(item){
            item.newGroupId='';
            item.newGroupLabel='调整';
            item.isFree=false;
            // this.curItem.haveSelectList = this.groupDetailMediaList;
            // this.haveSelectCnt = this.curItem.haveSelectList.length;
            // this.getCurrentUseIds();
            // this.adjustCost = Number(this.adjustCost)-Number(item.cost);
        },

        onConfirmChange(){
            this.getCurrentUseIds();
            this.tmpData.haveSelectListIds=this.currentUseIds;
            this.tmpData.adjLog=this.currentadjLog;
            
            this.$emit('changeFinished', this.tmpData);
        },

        async openPackage(val){
            await this.$store.dispatch('operate/GetPackageMediaList',{groupId:val}).then(()=>{   
                var obj=[];
                var path = getImagePath();
                // console.log('aa',this.GetPackageMediaList)
                this.GetPackageMediaList[1].forEach(element => {
                    var imgObj=[];
                    if (element.images){
                        var imgArr=element.images.split(';');
                        if (imgArr && imgArr.length>0){
                            imgArr.forEach(ele2 => {
                                imgObj.push(path+ele2);
                            });
                        }
                    }
                    element.imgList=imgObj;
                    obj.push(element);
                });
                this.packageMediaList = obj;
                // console.log('aa',this.packageMediaList)
                this.viewPackageVisible=true;      
            })   
        },

            open(){
                // console.log('do open')
                this.$nextTick(() => {
                    //  执行echarts方法
                    // for (let i = 0; i < this.tableData.length; i++) {
                    //     this.$refs.productMediaTable.toggleRowSelection(this.tableData[i])
                    // }
                })
            },

            clearSelect(){
                for (let i = 0; i < this.tmpData.length; i++) {
                        this.$refs.productMediaTable.toggleRowSelection(this.tmpData[i],false)
                    }
            },

            onRemove(){
                this.$confirm('确定移除选中套餐, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.haveSelectList.some((selItem)=>{
                     this.tmpData.some((item, i)=>{
                         if(item.groupId==selItem.groupId){
                             this.tmpData.splice(i,1);
                             return true
                         }
                        }); 
                        this.detailKey='';
                    });
                    this.haveSelectList=[];
                    this.clearSelect();
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
                    });                 
            },

            //产品下套餐列表的checkbox点击事件
            handleCurrentChange(row, event, column){
                // console.log('do')
                // this.$refs.productMediaTable.toggleRowSelection(row,true);//点击选中
            },

            selsChange(val){
                // console.log('val',val)
                this.haveSelectList = val;
                // this.curItem.haveSelectCnt = this.curItem.haveSelectList.length;
                // console.log('aa',this.sels)
            },

            getProductRowKey(row){
                return row.groupId;
            },

            close(){
                this.$emit('close');
            },

            // 每页多少条
            handleZyxlSizeChange(val) {
                this.zyxl.pageSize = val;
            },
            // 当前页
            handleZyxlCurrentChange(val) {
                this.zyxl.currentPage = val;
            },
        }
    }
</script>
        
<style>
        
</style>